
export default {

  async getQuestionnaireQuestions(questionnaire) {

    const result = await window.axios({
      url: `/questionnaire/${questionnaire.id}/question`,
      method: "GET",
    });

    return result
  }
}
