<template>
  <Xlayout>
    <Xstep :step="1" />
    <!-- <div :class="$style.tip">
      展会现场需要刷身份证入场，请正确填写姓名，请勿填写他人姓名
    </div> -->

    <div v-if="questionnaire.description" :class="$style.tip">
      {{ questionnaire.description }}
    </div>

    <van-cell-group v-for="(question, index) in questions" :key="index">
      <!-- <Xitem :item="question" :formData="formData" @change="getValue" /> -->

      <question
        :value="questionAnswers[index]"
        :index="index + 1"
        :question="question"
        @change="handlerChange"
      />
    </van-cell-group>

    <div :class="$style.next">
      <Xbutton :loading="isLoading" @click="handleClickNext">下一步</Xbutton>
    </div>
  </Xlayout>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import { Toast } from "vant";

import Xlayout from "../components/layout.vue";
import Xstep from "../components/step.vue";
// import Xitem from "../components/form/item.vue";
import Xbutton from "../components/button.vue";

import Question from "../components/question/question.vue";

import QuestionnaireService from "../service/questionnaire";
import QuestionnaireQuestionService from "../service/questionnaire-question";
import UserVisitRecordService from "../service/user-visit-record";
// import UserQuestionAnswerService from "../service/user-question-answers";

import ApplicationUserQuestionAnswerService from "../service/application-user-question-answers";

import area from "../data/area";
import countries from "../data/country.json";

export default {
  components: { Xlayout, Xstep, Xbutton, Question },
  computed: {
    ...mapState({
      pickerTypes: (state) => state.pickerTypes,
      fieldTypes: (state) => state.fieldTypes,
      application: (state) => state.application,
      account: (state) => state.account,
      user: (state) => state.user,
      phone: (state) => state.phone,
      exhibition: (state) => state.exhibition,
      businessCard: (state) => state.businessCard,
      visitRecord: (state) => state.visitRecord,
      userQuestionnaireId: (state) =>
        state.application.properties.userQuestionnaireId,
      questionnaireId: (state) => state.application.properties.questionnaireId,
    }),
  },
  data() {
    return {
      isLoading: false,
      formList: [],
      formData: {},
      userQuestionnaire: {},
      questionnaire: {},
      questions: [],
      questionAnswers: [],
    };
  },
  methods: {
    ...mapMutations({
      setCountry: "setCountry",
      setProvince: "setProvince",
      setCity: "setCity",
      setDistrict: "setDistrict",
    }),
    ...mapActions({
      getVisitRecord: "getVisitRecord",
    }),
    handlerChange(questionAnswer) {
      this.questionAnswers[questionAnswer.index - 1] = questionAnswer;
    },
    async handleClickNext() {
      try {
        this.isLoading = true;
        console.log("提交");

        console.log(this.questionAnswers);

        await this.addQuestionAwswers();

        if (this.questionnaireId === "") {
          this.$router.push("/card");
        } else {
          this.$router.push("/questionnaire");
        }
      } catch (error) {
        console.log(error);

        // 危险通知
        Toast({
          type: "fail",
          message: error.response ? error.response.data.message : error.message,
        });
        this.isLoading = false;
      } finally {
        console.log("finally");
      }
    },

    async addQuestionAwswers() {
      for (const questionIndex in this.questions) {
        if (this.questions[questionIndex].required) {
          //必填
          if (
            this.questionAnswers[questionIndex].value ||
            (this.questionAnswers[questionIndex].opetions &&
              this.questionAnswers[questionIndex].opetions.length > 0)
          ) {
            //有数据
          } else {
            // Toast({
            //   type: "fail",
            //   message: `${this.questions[questionIndex].content}为必答题`,
            // });
            throw new Error(`${this.questions[questionIndex].content}为必答题`);
          }
        }
      }

      await ApplicationUserQuestionAnswerService.addApplicationUserQuestionAnswers(
        this.application,
        this.user,
        this.questionAnswers.filter((answer) => !!answer)
      );

      const payload = this.questionAnswers.reduce((pre, cur) => {
        if (cur.question.key) {
          pre[cur.question.key] = cur.value || "";
        }
        return pre;
      }, {});

      console.log(payload);

      const params = this.visitRecord;

      params.properties = {
        ...params.properties,
        ...payload,
        applicationId: this.application.id,
        questionnaires: [this.userQuestionnaireId],
      };

      if (this.questionnaireId === "") {
        params.progress = "REGISTERED";
      } else {
        params.progress = "PERFECT_INFORMATION";
      }

      await UserVisitRecordService.updateUserVisitRecord(this.user, params);

      await this.getVisitRecord();
    },
  },
  async mounted() {
    // try {
    //   this.isLoading = true;
    //   await this.getVisitRecord();

    //   const visitRecord = this.visitRecord;

    //   if (
    //     visitRecord.properties.questionnaires &&
    //     visitRecord.properties.questionnaires.length > 0
    //   ) {
    //     const questionnaires = visitRecord.properties.questionnaires;

    //     if (questionnaires.includes(this.userQuestionnaireId)) {
    //       if (questionnaires.includes(this.questionnaireId)) {
    //         window.$router.push("/card");
    //       } else {
    //         window.$router.push("/questionnaire");
    //       }
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    this.questionnaire = await QuestionnaireService.getQuestionnaire({
      id: this.userQuestionnaireId,
    });

    const questions =
      await QuestionnaireQuestionService.getQuestionnaireQuestions({
        id: this.userQuestionnaireId,
      });

    const questionAnswers = [];

    for (const question of questions) {
      const questionAnswer = {
        question: question,
      };

      switch (question.type) {
        case "USER_PHONE":
          //手机号特别处理
          question.disabled = true;

          if (
            this.visitRecord.properties.phone &&
            this.visitRecord.properties.phone != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.phone;
          }

          if (
            this.user.phone &&
            this.user.phone.number &&
            this.user.phone.number != ""
          ) {
            questionAnswer.value = this.user.phone.number;
          }

          if (this.phone && this.phone.number && this.phone.number != "") {
            questionAnswer.value = this.phone.number;
          }
          break;
        case "USER_NAME":
          if (
            this.visitRecord.properties.name &&
            this.visitRecord.properties.name != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.name;
          }
          if (this.businessCard.PsnName && this.businessCard.PsnName != "") {
            questionAnswer.value = this.businessCard.PsnName;
          }
          break;
        case "USER_IDENTIFICATION":
          if (
            this.visitRecord.properties.identification &&
            this.visitRecord.properties.identification != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.identification;
          }
          break;
        case "USER_CORPORATION":
          if (
            this.visitRecord.properties.corporation &&
            this.visitRecord.properties.corporation != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.corporation;
          }
          if (
            this.businessCard.PsnComName &&
            this.businessCard.PsnComName != ""
          ) {
            questionAnswer.value = this.businessCard.PsnComName;
          }
          break;
        case "USER_DUTY":
          if (
            this.visitRecord.properties.duty &&
            this.visitRecord.properties.duty != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.duty;
          }
          if (this.businessCard.PsnRank && this.businessCard.PsnRank != "") {
            questionAnswer.value = this.businessCard.PsnRank;
          }
          break;
        case "USER_EMAIL":
          if (
            this.visitRecord.properties.email &&
            this.visitRecord.properties.email != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.email;
          }
          if (this.businessCard.PsnEmail && this.businessCard.PsnEmail != "") {
            questionAnswer.value = this.businessCard.PsnEmail;
          }
          break;
        case "USER_COUNTRY":
          if (
            this.visitRecord.properties.country &&
            this.visitRecord.properties.country != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.country;

            this.setCountry(
              countries.find((country) => {
                return country.cnname === this.visitRecord.properties.country;
              }).numeric || ""
            );
          }
          break;
        case "USER_PROVINCE":
          if (
            this.visitRecord.properties.provice &&
            this.visitRecord.properties.provice != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.provice;

            this.setProvince(
              Object.keys(area.province_list).find((code) => {
                return (
                  area.province_list[code] ===
                  this.visitRecord.properties.provice
                );
              }) || ""
            );
          }
          break;
        case "USER_CITY":
          if (
            this.visitRecord.properties.city &&
            this.visitRecord.properties.city != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.city;

            this.setCity(
              Object.keys(area.city_list).find((code) => {
                return (
                  area.city_list[code] === this.visitRecord.properties.city
                );
              }) || ""
            );
          }
          break;
        case "USER_DISTRICT":
          if (
            this.visitRecord.properties.district &&
            this.visitRecord.properties.district != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.district;
          }
          break;
        case "USER_LOCATION":
          if (
            this.visitRecord.properties.location &&
            this.visitRecord.properties.location != ""
          ) {
            questionAnswer.value = this.visitRecord.properties.location;
          }
          if (
            this.businessCard.PsnAddress &&
            this.businessCard.PsnAddress != ""
          ) {
            questionAnswer.value = this.businessCard.PsnAddress;
          }
          break;
        default:
          break;
      }

      questionAnswers.push(questionAnswer);
    }

    this.questions = questions;
    this.questionAnswers = questionAnswers;
    this.isLoading = false;
  },
};
</script>

<style lang="less" module>
.tip {
  background: #ffebdf;
  font-size: 14px;
  padding: 15px;
  color: #ff720a;

  a {
    font-size: 14px;
    color: #ff720a;
  }
}
.next {
  padding: 15px;
}
</style>