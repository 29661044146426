

export default {

    addApplicationUserQuestionAnswers: async (application, user, questionAnswerParams) => {

        const questionAnswers = await window.axios({
            url: `/application/${application.id}/user/${user.id}/question-answers`,
            method: "POST",
            data: questionAnswerParams,
        });
        return questionAnswers
    },
}